import React from 'react';

import { NAVLINKS } from "consts"
import './Home.scss';

export class Home extends React.Component<any, any> {
  public render() {
    return (
      <div className="home">
        <div className="slider">
          <div className="intro-text">
            <p>
              Tervezze meg polcrendszerét online, egyedi igényeivel pedig
              forduljon hozzánk bizalommal.
            </p>
          </div>
        </div>
        <h1>
          Polcrendszerek
        </h1>
        <p>
          Több mint 20 éve vagyunk a polcos piacon, ez idő alatt rengeteg ügyfél tisztelt meg a bizalmával.
          Építettünk számos irattárat és raktárat különböző cégeknek, munkaügyi központokban, egyéb állami és
          önkormányzati intézményekben. Nem csak a helyszínek voltak változatosak, hanem a felhasznált
          polcrendszerek is, mint például az APROD csavarmentes polcrendszer, S1 csavarmentes polcrendszer,
          illetve a Salgó csavarkötésű polcrendszer.
        </p>
        <p>
          Egy irattár építése vagy egy raktár bepolcozása minden esetben a helyszíni felméréssel kezdődik,
          kivéve azokban az esetekben, amikor pontos alaprajzot kapunk a helyiségről. Ezután elkészítjük a
          polcozási tervet a megrendelő igényei szerint, emellett mellékeljük az árajánlatot is. A költségeket
          befolyásolhatja a szállítási és szerelési költség, persze ha igényt tartanak ezekre a szolgáltatásainkra.
        </p>
        <p>
          Megrendelés esetén már csak a kivitelezés van hátra. A teljesség igénye nélkül az alábbiakban látható
          néhány fénykép az épített irattárakról, raktárakról.
        </p>
        <div className="padding-top-30">
          <div className="box padding-right-25">
            <a href={NAVLINKS.polctervezo.aprod}>
              <div className="image-container">
                <img src="images/content/home/aprod.jpg" alt="Aprod polcrendszer" />
              </div>
              <div className="text">
                <h2>APROD tervező megnyitása</h2>
              </div>
            </a>
          </div>
          <div className="box padding-left-25">
            <a href={NAVLINKS.polctervezo.salgoCsavarmentes}>
              <div className="image-container">
                <img src="images/content/home/salgo-csavarmentes.jpg" alt="Salgó csavarmentes polcrendszer" />
              </div>
              <div className="text">
                <h2>Salgó csavarmentes tervező megnyitása</h2>
              </div>
            </a>
          </div>
        </div>
        <div className="clear"></div>
        <div className="padding-top-30">
          <div className="box padding-right-25">
            <a href={NAVLINKS.polctervezo.salgoMetrikus}>
              <div className="image-container">
                <img src="images/content/home/salgo-metrikus.jpg" alt="Salgó metrikus polcrendszer" />
              </div>
              <div className="text">
                <h2>Salgó metrikus tervező megnyitása</h2>
              </div>
            </a>
          </div>
          <div className="box padding-left-25">
            <a href={NAVLINKS.polctervezo.salgoMetrikus}>
              <div className="image-container">
                <img src="images/content/home/salgo-colos.jpg" alt="Salgó colos polcrendszer" />
              </div>
              <div className="text">
                <h2>Salgó colos tervező megnyitása</h2>
              </div>
            </a>
          </div>
        </div>
        <div className="clear"></div>
      </div>
    );
  }
}

export default Home;