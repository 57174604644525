import { StandardShelfCalculator } from './../Standard';
import { IPrice } from '../Standard/Model/IPrice';
import { IColumn } from "../Standard/Model/IColumn";
import { SurfaceTypesEnum } from '../Standard/Enum/SurfaceTypesEnum'

export class SalgoColosShelfCalculator extends StandardShelfCalculator {
  // Polctalp
  private sole: IPrice[] = [];
  // Csavar
  private screwPrice: number = 0;
  // Saroklemez
  private cornerPrice: number = 0;
  // Oszlopok
  private columns: IColumn[] = [];

  constructor(props) {
    super(props);

    this.type = "salgo-colos";
    this.name = "Salgó colos polcrendszer tervező";
    this.previewImage = "/images/content/preview/salgo.jpg";

    this.surfaces = [SurfaceTypesEnum.Festett, SurfaceTypesEnum.Horganyzott];
    this.shelfsNumber = [4, 5, 6, 7];
    this.fieldsNumber = [1, 2, 3, 4];

    this.columns = [
      { height: 1981, price: [{ surface: SurfaceTypesEnum.Festett, price: 4149}, { surface: SurfaceTypesEnum.Horganyzott, price: 2862}] },
      { height: 2438, price: [{ surface: SurfaceTypesEnum.Festett, price: 4461}, { surface: SurfaceTypesEnum.Horganyzott, price: 3081}] },
    ];

    this.shelfs = [
      { width: 1219, depth: 915, price: [{ surface: SurfaceTypesEnum.Festett, price: 18551}, { surface: SurfaceTypesEnum.Horganyzott, price: 12379}] },
      { width: 1219, depth: 762, price: [{ surface: SurfaceTypesEnum.Festett, price: 15647}, { surface: SurfaceTypesEnum.Horganyzott, price: 10435}] },
      { width: 1219, depth: 610, price: [{ surface: SurfaceTypesEnum.Festett, price: 13105}, { surface: SurfaceTypesEnum.Horganyzott, price: 8815}] },
      { width: 1219, depth: 457, price: [{ surface: SurfaceTypesEnum.Festett, price: 10415}, { surface: SurfaceTypesEnum.Horganyzott, price: 7020}] },
      { width: 1219, depth: 381, price: [{ surface: SurfaceTypesEnum.Festett, price: 9103}, { surface: SurfaceTypesEnum.Horganyzott, price: 6142}] },
      { width: 1219, depth: 305, price: [{ surface: SurfaceTypesEnum.Festett, price: 7807}, { surface: SurfaceTypesEnum.Horganyzott, price: 5251}] },
      { width: 1219, depth: 229, price: [{ surface: SurfaceTypesEnum.Festett, price: 6495}, { surface: SurfaceTypesEnum.Horganyzott, price: 4360}] },
      { width: 991, depth: 915, price: [{ surface: SurfaceTypesEnum.Festett, price: 15533}, { surface: SurfaceTypesEnum.Horganyzott, price: 10233}] },
      { width: 991, depth: 762, price: [{ surface: SurfaceTypesEnum.Festett, price: 13269}, { surface: SurfaceTypesEnum.Horganyzott, price: 8761}] },
      { width: 991, depth: 610, price: [{ surface: SurfaceTypesEnum.Festett, price: 10940}, { surface: SurfaceTypesEnum.Horganyzott, price: 7317}] },
      { width: 991, depth: 457, price: [{ surface: SurfaceTypesEnum.Festett, price: 8709}, { surface: SurfaceTypesEnum.Horganyzott, price: 5845}] },
      { width: 991, depth: 381, price: [{ surface: SurfaceTypesEnum.Festett, price: 7659}, { surface: SurfaceTypesEnum.Horganyzott, price: 5103}] },
      { width: 991, depth: 305, price: [{ surface: SurfaceTypesEnum.Festett, price: 6577}, { surface: SurfaceTypesEnum.Horganyzott, price: 4374}] },
      { width: 991, depth: 229, price: [{ surface: SurfaceTypesEnum.Festett, price: 5511}, { surface: SurfaceTypesEnum.Horganyzott, price: 3645}] },
      { width: 915, depth: 915, price: [{ surface: SurfaceTypesEnum.Festett, price: 14483}, { surface: SurfaceTypesEnum.Horganyzott, price: 9504}] },
      { width: 915, depth: 762, price: [{ surface: SurfaceTypesEnum.Festett, price: 12400}, { surface: SurfaceTypesEnum.Horganyzott, price: 8154}] },
      { width: 915, depth: 610, price: [{ surface: SurfaceTypesEnum.Festett, price: 10235}, { surface: SurfaceTypesEnum.Horganyzott, price: 6804}] },
      { width: 915, depth: 457, price: [{ surface: SurfaceTypesEnum.Festett, price: 8168}, { surface: SurfaceTypesEnum.Horganyzott, price: 5427}] },
      { width: 915, depth: 381, price: [{ surface: SurfaceTypesEnum.Festett, price: 7151}, { surface: SurfaceTypesEnum.Horganyzott, price: 4738}] },
      { width: 915, depth: 305, price: [{ surface: SurfaceTypesEnum.Festett, price: 6150}, { surface: SurfaceTypesEnum.Horganyzott, price: 4077}] },
      { width: 915, depth: 229, price: [{ surface: SurfaceTypesEnum.Festett, price: 5133}, { surface: SurfaceTypesEnum.Horganyzott, price: 3402}] },
      { width: 762, depth: 762, price: [{ surface: SurfaceTypesEnum.Festett, price: 10415}, { surface: SurfaceTypesEnum.Horganyzott, price: 6750}] },
      { width: 762, depth: 610, price: [{ surface: SurfaceTypesEnum.Festett, price: 8808}, { surface: SurfaceTypesEnum.Horganyzott, price: 5791}] },
      { width: 762, depth: 457, price: [{ surface: SurfaceTypesEnum.Festett, price: 7036}, { surface: SurfaceTypesEnum.Horganyzott, price: 4644}] },
      { width: 762, depth: 381, price: [{ surface: SurfaceTypesEnum.Festett, price: 6183}, { surface: SurfaceTypesEnum.Horganyzott, price: 4063}] },
      { width: 762, depth: 305, price: [{ surface: SurfaceTypesEnum.Festett, price: 5314}, { surface: SurfaceTypesEnum.Horganyzott, price: 3496}] },
      { width: 762, depth: 229, price: [{ surface: SurfaceTypesEnum.Festett, price: 4461}, { surface: SurfaceTypesEnum.Horganyzott, price: 2929}] },
      { width: 610, depth: 610, price: [{ surface: SurfaceTypesEnum.Festett, price: 7364}, { surface: SurfaceTypesEnum.Horganyzott, price: 4792}] },
      { width: 610, depth: 457, price: [{ surface: SurfaceTypesEnum.Festett, price: 5904}, { surface: SurfaceTypesEnum.Horganyzott, price: 3847}] },
      { width: 610, depth: 381, price: [{ surface: SurfaceTypesEnum.Festett, price: 5199}, { surface: SurfaceTypesEnum.Horganyzott, price: 3375}] },
      { width: 610, depth: 305, price: [{ surface: SurfaceTypesEnum.Festett, price: 4494}, { surface: SurfaceTypesEnum.Horganyzott, price: 2902}] },
      { width: 610, depth: 229, price: [{ surface: SurfaceTypesEnum.Festett, price: 3788}, { surface: SurfaceTypesEnum.Horganyzott, price: 2443}] }
    ];

    this.sole = [
      { price: 557, surface: SurfaceTypesEnum.Festett },
      { price: 256, surface: SurfaceTypesEnum.Horganyzott }
    ];

    this.screwPrice = 40;
    this.cornerPrice = 67;
    this.multiplier = 1;

    this.init();

    let heights: number[] = [];
    for (let i = 0; i < this.columns.length; i++) {
      heights.push(this.columns[i].height);
    }
    this.setHeights(heights);
  }

  calculatePrice() {
    let system = this.getSystem();
    this.logger.log(system);

    let systemPrice = 0;
    let currentSurface = system.surface;
    let depth = system.depth;

    let columnPrice = this.columns
      .find(i => i.height === system.height)!.price
      .find(i => i.surface === currentSurface)!.price;
    let columnsNumber = 4 + (system.fieldsNumber - 1) * 2;
    let currentSolePrice = this.sole.find(i => i.surface === currentSurface)!.price;
    let columnsTotalPrice = (currentSolePrice + (this.screwPrice * 2) + columnPrice) * columnsNumber;
    systemPrice += columnsTotalPrice;

    let fieldsPrice: number[] = [];
    for (let i = 0; i < system.fieldsNumber; i++) {
      let shelfValue = this.shelfs
        .find(current => current.depth === depth && current.width === system.shelfsWidth[i])!.price
        .find(current => current.surface === currentSurface)!.price;

      this.logger.log("Polc ára:", shelfValue);

      fieldsPrice[i] = shelfValue * system.shelfsNumber
        + 3 * (8 * (this.cornerPrice + 3 * this.screwPrice))
        + (system.shelfsNumber - 3) * (4 * 2 * this.screwPrice);

      systemPrice += fieldsPrice[i];
    }

    systemPrice *= this.multiplier;

    this.logger.log("calculatePrice SalgoMetrikusShelfCalculator");
    this.logger.log("Oszlopok száma:", columnsNumber);
    this.logger.log("Oszlop ára:", columnPrice);
    this.logger.log("Talp ára:", currentSolePrice);
    this.logger.log("Csavar ára:", this.screwPrice);
    this.logger.log("Oszlopok ára:", columnsTotalPrice);
    for (let i = 0; i < system.fieldsNumber; i++) {
      this.logger.log("#" + (i + 1), "mező ára:", fieldsPrice[i]);
    }
    this.logger.log("Rendszer ára:", systemPrice); 

    this.setState({
      netPrice: systemPrice,
      grossPrice: systemPrice * 1.27
    })
  }
}

export default SalgoColosShelfCalculator;