import React from 'react';

import './Contact.scss';

export class Contact extends React.Component<any, any> {
    public render() {
      return (
        <div className="contact">
          <div className="single-page-title-container">
            <h1>Kapcsolat</h1>
          </div>
          <h2>Nem találja amire szüksége van?</h2>
          <p>
            Vegye fel velünk a kapcsolatot, amennyiben egyedi polcrendszerre van szükséges.
          </p>
          <h3>Juhász és Társa Bt.</h3>
          <p>
            <strong>Cím: </strong>2510 Dorog, Esztergomi út 39.<br/>
            <strong>Telefonszám: </strong>+36-20-968-2309<br/>
            <strong>Email cím: </strong> info@juhaszpolcrendszer.hu<br/>
            <strong>Adószám: </strong>23647783-2-11
          </p>
          <h3>GPS</h3>
          <p>
            É47.7351574<br/>
            K18.724876
          </p>
          <p>
            <a href="https://g.page/juhaszpolcrendszer?share" target="_blank">Megtekintés Google Térképen</a>
          </p>
          <h3>
            Nyitvatartás
          </h3>
          <table className="open-time">
            <tr>
              <td>H-P:</td>
              <td>7:20-14:30</td>
            </tr>
            <tr>
              <td>SZ</td>
              <td>8:00-12:00</td>
            </tr>
            <tr>
              <td>Vasárnap</td>
              <td>Zárva</td>
            </tr>
          </table>
        </div>
      );
  }
}

export default Contact;