import { SurfaceTypesEnum } from '../Standard/Enum/SurfaceTypesEnum'
import { IFrame } from "./Model/IFrame"
import { StandardShelfCalculator } from './../Standard';
import { ILongeron } from './Model/ILongeron';

export class AprodShelfCalculator extends StandardShelfCalculator {
  // Hossztartók  
  private longerons: ILongeron[] = [];
  // Keretek
  private frames: IFrame[] = [];
  // Kapocs
  private clipPrice: number = 0;

  constructor(props) {
    super(props);

    this.type = "aprod";
    this.name = "APROD polcrendszer tervező";
    this.previewImage = "/images/content/preview/aprod.jpg";

    this.surfaces = [SurfaceTypesEnum.Festett, SurfaceTypesEnum.Horganyzott];
    this.shelfsNumber = [4, 5, 6, 7];
    this.fieldsNumber = [1, 2, 3, 4];

    this.shelfs = [
      { width: 1200, depth: 800, price: [{ surface: SurfaceTypesEnum.Festett, price: 17256}, { surface: SurfaceTypesEnum.Horganyzott, price: 12294 }] },
      { width: 1200, depth: 700, price: [{ surface: SurfaceTypesEnum.Festett, price: 15563}, { surface: SurfaceTypesEnum.Horganyzott, price: 11088 }] },
      { width: 1200, depth: 600, price: [{ surface: SurfaceTypesEnum.Festett, price: 14144}, { surface: SurfaceTypesEnum.Horganyzott, price: 10081 }] },
      { width: 1200, depth: 500, price: [{ surface: SurfaceTypesEnum.Festett, price: 12419}, { surface: SurfaceTypesEnum.Horganyzott, price: 8850 }] },
      { width: 1200, depth: 400, price: [{ surface: SurfaceTypesEnum.Festett, price: 10938}, { surface: SurfaceTypesEnum.Horganyzott, price: 7788 }] },
      { width: 1200, depth: 300, price: [{ surface: SurfaceTypesEnum.Festett, price: 8800}, { surface: SurfaceTypesEnum.Horganyzott, price: 6263 }] },
      { width: 1000, depth: 800, price: [{ surface: SurfaceTypesEnum.Festett, price: 14000}, { surface: SurfaceTypesEnum.Horganyzott, price: 9044 }] },
      { width: 1000, depth: 700, price: [{ surface: SurfaceTypesEnum.Festett, price: 11813}, { surface: SurfaceTypesEnum.Horganyzott, price: 8400 }] },
      { width: 1000, depth: 600, price: [{ surface: SurfaceTypesEnum.Festett, price: 10863}, { surface: SurfaceTypesEnum.Horganyzott, price: 7750 }] },
      { width: 1000, depth: 500, price: [{ surface: SurfaceTypesEnum.Festett, price: 9038}, { surface: SurfaceTypesEnum.Horganyzott, price: 6425 }] },
      { width: 1000, depth: 400, price: [{ surface: SurfaceTypesEnum.Festett, price: 7388}, { surface: SurfaceTypesEnum.Horganyzott, price: 5263 }] },
      { width: 1000, depth: 300, price: [{ surface: SurfaceTypesEnum.Festett, price: 5825}, { surface: SurfaceTypesEnum.Horganyzott, price: 4156 }] },
      { width: 800, depth: 800, price: [{ surface: SurfaceTypesEnum.Festett, price: 13494}, { surface: SurfaceTypesEnum.Horganyzott, price: 8919 }] },
      { width: 800, depth: 700, price: [{ surface: SurfaceTypesEnum.Festett, price: 11031}, { surface: SurfaceTypesEnum.Horganyzott, price: 7850 }] },
      { width: 800, depth: 600, price: [{ surface: SurfaceTypesEnum.Festett, price: 9875}, { surface: SurfaceTypesEnum.Horganyzott, price: 7038 }] },
      { width: 800, depth: 500, price: [{ surface: SurfaceTypesEnum.Festett, price: 8306}, { surface: SurfaceTypesEnum.Horganyzott, price: 5919 }] },
      { width: 800, depth: 400, price: [{ surface: SurfaceTypesEnum.Festett, price: 6919}, { surface: SurfaceTypesEnum.Horganyzott, price: 4938 }] },
      { width: 800, depth: 300, price: [{ surface: SurfaceTypesEnum.Festett, price: 5469}, { surface: SurfaceTypesEnum.Horganyzott, price: 3894 }] },
      { width: 600, depth: 600, price: [{ surface: SurfaceTypesEnum.Festett, price: 8400}, { surface: SurfaceTypesEnum.Horganyzott, price: 5988 }] },
      { width: 600, depth: 500, price: [{ surface: SurfaceTypesEnum.Festett, price: 7094}, { surface: SurfaceTypesEnum.Horganyzott, price: 5056 }] },
      { width: 600, depth: 400, price: [{ surface: SurfaceTypesEnum.Festett, price: 5900}, { surface: SurfaceTypesEnum.Horganyzott, price: 4188 }] },
      { width: 600, depth: 300, price: [{ surface: SurfaceTypesEnum.Festett, price: 4719}, { surface: SurfaceTypesEnum.Horganyzott, price: 3350 }] }
    ];

    this.longerons = [
      { width: 1200, securityClip: 44, price: [{ surface: SurfaceTypesEnum.Festett, price: 3431}, { surface: SurfaceTypesEnum.Horganyzott, price: 2438}] },
      { width: 1000, securityClip: 44, price: [{ surface: SurfaceTypesEnum.Festett, price: 3094}, { surface: SurfaceTypesEnum.Horganyzott, price: 2200}] }, 
      { width: 800, securityClip: 44, price: [{ surface: SurfaceTypesEnum.Festett, price: 2969}, { surface: SurfaceTypesEnum.Horganyzott, price: 2113}] },
      { width: 600, securityClip: 44, price: [{ surface: SurfaceTypesEnum.Festett, price: 2825}, { surface: SurfaceTypesEnum.Horganyzott, price: 2025}] }
    ];

    this.frames = [{
      height: 2500, depth: 800, closingPlugPrice: 50, price: [{ surface: SurfaceTypesEnum.Festett, price: 24063}, { surface: SurfaceTypesEnum.Horganyzott, price: 17144 }]}, {
      height: 2500, depth: 700, closingPlugPrice: 50, price: [{ surface: SurfaceTypesEnum.Festett, price: 23075}, { surface: SurfaceTypesEnum.Horganyzott, price: 16450 }]}, {
      height: 2500, depth: 600, closingPlugPrice: 50, price: [{ surface: SurfaceTypesEnum.Festett, price: 22044}, { surface: SurfaceTypesEnum.Horganyzott, price: 15713 }]}, {
      height: 2500, depth: 500, closingPlugPrice: 50, price: [{ surface: SurfaceTypesEnum.Festett, price: 21175}, { surface: SurfaceTypesEnum.Horganyzott, price: 15075 }]}, {
      height: 2500, depth: 400, closingPlugPrice: 50, price: [{ surface: SurfaceTypesEnum.Festett, price: 20638}, { surface: SurfaceTypesEnum.Horganyzott, price: 14706 }]}, {
      height: 2500, depth: 300, closingPlugPrice: 50, price: [{ surface: SurfaceTypesEnum.Festett, price: 19869}, { surface: SurfaceTypesEnum.Horganyzott, price: 14150 }]}, {
      height: 2000, depth: 800, closingPlugPrice: 50, price: [{ surface: SurfaceTypesEnum.Festett, price: 19244}, { surface: SurfaceTypesEnum.Horganyzott, price: 13700 }]}, {
      height: 2000, depth: 700, closingPlugPrice: 50, price: [{ surface: SurfaceTypesEnum.Festett, price: 18275}, { surface: SurfaceTypesEnum.Horganyzott, price: 13013 }]}, {
      height: 2000, depth: 600, closingPlugPrice: 50, price: [{ surface: SurfaceTypesEnum.Festett, price: 17444}, { surface: SurfaceTypesEnum.Horganyzott, price: 12438 }]}, {
      height: 2000, depth: 500, closingPlugPrice: 50, price: [{ surface: SurfaceTypesEnum.Festett, price: 16538}, { surface: SurfaceTypesEnum.Horganyzott, price: 11775 }]}, {
      height: 2000, depth: 400, closingPlugPrice: 50, price: [{ surface: SurfaceTypesEnum.Festett, price: 15806}, { surface: SurfaceTypesEnum.Horganyzott, price: 11256 }]}, {
      height: 2000, depth: 300, closingPlugPrice: 50, price: [{ surface: SurfaceTypesEnum.Festett, price: 15375}, { surface: SurfaceTypesEnum.Horganyzott, price: 10944 }]}
    ];

    this.clipPrice = 181;
    this.multiplier = 1;

    this.init();

    let heights: number[] = [];
    for (let i = 0; i < this.frames.length; i++) {
      heights.push(this.frames[i].height);
    }
    this.setHeights(heights);
  }

  calculatePrice() {
    this.logger.log("calculatePrice AprodShelfCalculator");

    let system = this.getSystem();
    let depth = system.depth;
    let surface = system.surface;
    let height = system.height;

    let framePrice = this.frames.find(current => current.depth === depth && current.height === height)!.price
      .find(current => current.surface === surface)!.price;
    this.logger.log("Keret:", framePrice);
    let framesNumber = system.fieldsNumber;
    framesNumber++;
    let framesTotalPrice = framePrice * framesNumber;

    let closingPlugPrice = this.frames.find(current => current.depth === depth && current.height === height)!.closingPlugPrice;
    let closingPlugNumber = framesNumber * 2;
    let closingPlugTotalPrice = closingPlugNumber * closingPlugPrice;

    let longeronNumber = height <= 2000 ? 4 : 6;
    let securityClipsNumber = longeronNumber * 2;
    let shelfsTotalPrice = 0;
    let longeronTotalPrice = 0;
    let securityClipsTotalPrice = 0;

    for (let i = 0; i < system.fieldsNumber; i++) {
      let shelfValue = this.shelfs
        .find(current => current.depth === depth && current.width === system.shelfsWidth[i])!.price
        .find(current => current.surface === surface)!.price;
      let longeronPrice = this.longerons
        .find(current => current.width === system.shelfsWidth[i])!.price
        .find(current => current.surface === surface)!.price;

      let securityClipPrice = this.longerons
        .find(current => current.width === system.shelfsWidth[i])!.securityClip;

      shelfsTotalPrice += system.shelfsNumber * shelfValue;
      longeronTotalPrice += longeronNumber * longeronPrice;
      securityClipsTotalPrice += securityClipsNumber * securityClipPrice;
    }

    let clipNumber = ((system.shelfsNumber  - 2 )* system.fieldsNumber) * 4;
    let clipsTotalPrice = clipNumber * this.clipPrice;

    this.logger.log("Polcok:",  system.shelfsNumber, shelfsTotalPrice);
    this.logger.log("Hossztartók:", longeronTotalPrice);
    this.logger.log("Polctartó kapcsok:", clipNumber, clipsTotalPrice);
    this.logger.log("Biztonsági kapcsok:", securityClipsTotalPrice);
    this.logger.log("Keretek:", framesTotalPrice);
    this.logger.log("Záródugók:", closingPlugTotalPrice);

    let systemPrice = shelfsTotalPrice + longeronTotalPrice + clipsTotalPrice + securityClipsTotalPrice + framesTotalPrice + closingPlugTotalPrice;

    systemPrice *= this.multiplier;
    this.setState({
      netPrice: systemPrice,
      grossPrice: systemPrice * 1.27
    })
  }
}

export default AprodShelfCalculator;