import React from 'react';
import Select from 'react-select'
import { NumericFormat } from 'react-number-format';
import axios from 'axios';

import { NAVLINKS, SITE } from "consts";
import { IShelf } from "./Model/IShelf";
import { ISystem } from "./Model/ISystem";
import { ISelect } from "./Model/ISelect";
import { SurfaceTypesEnum } from './Enum/SurfaceTypesEnum'
import { Logger } from 'logger'
import { VirtualShelf } from './VirtualShelf/drawer';

import './Checkbox.scss';
import './StandardShelfCalculator.scss';

interface IProps {
}

enum SendStatusEnum {
  Never,
  InProgress,
  Success,
  Failed
}

enum ValidationInputState {
  New,
  Invalid,
  Valid,
}

interface IState {
  netPrice: number,
  grossPrice: number,
  previewTab: number,
  system: ISystem,
  contact: {
    name: string,
    telephone: string,
    email: string,
    comment: string,
    termsAccepted: boolean,
    sendStatus: SendStatusEnum,
    fieldValidation: {
      name: ValidationInputState,
      telephone: ValidationInputState,
      email: ValidationInputState,
      termsAccepted: ValidationInputState
    }
  }
}

export abstract class StandardShelfCalculator extends React.Component<IProps, IState> {
  protected logger: Logger = new Logger();
  protected type: string = "";
  protected name: string = "";
  protected previewImage: string = "";

  protected surfaces: SurfaceTypesEnum[] = [];
  protected shelfs: IShelf[] = [];
  protected shelfsNumber: number[] = [];
  protected fieldsNumber: number[] = [];

  protected multiplier: number = 1;

  private surfacesText: { value: SurfaceTypesEnum, label: string }[] = [
    { value: SurfaceTypesEnum.Festett, label: "Festett" },
    { value: SurfaceTypesEnum.Horganyzott, label: "Horganyzott" }
  ];
  private surfacesSelect: ISelect[] = [];
  private shelfsNumberSelect: ISelect[] = [];
  private fieldsNumberSelect: ISelect[] = [];
  private shelfsWidthSelect: ISelect[] = [];
  private depthsSelect: ISelect[] = [];
  private heightsSelect: ISelect[] = [];

  private virtualShelfContainerId: string = "virtualShelfContainer";
  private virtualShelf: VirtualShelf = new VirtualShelf(this.virtualShelfContainerId);

  abstract calculatePrice();

  constructor(props) {
    super(props);

    this.state = {
      netPrice: 0,
      grossPrice: 0,
      previewTab: 0,
      system: {
        surface: 0,
        fieldsNumber: 1,
        depth: 0,
        shelfsWidth: [],
        height: 0,
        shelfsNumber: 1
      },
      contact: {
        name: "",
        telephone: "",
        email: "",
        comment: "",
        termsAccepted: false,
        sendStatus: SendStatusEnum.Never,
        fieldValidation: {
          email: ValidationInputState.New,
          name: ValidationInputState.New,
          telephone: ValidationInputState.New,
          termsAccepted: ValidationInputState.New
        }
      }
    };

    this.previewTabChange = this.previewTabChange.bind(this);
    this.checkShelfsWidth = this.checkShelfsWidth.bind(this);
    this.sendContact = this.sendContact.bind(this);
  }

  protected setHeights(heights: number[]) {
    heights = heights.sort(function (a, b) { return a - b; });

    for (let i = 0; i < heights.length; i++) {
      if (!this.heightsSelect.find(currenct => currenct.value === heights[i])) {
        this.heightsSelect.push({
          value: heights[i],
          label: heights[i] + " mm"
        });
      }
    }
  }

  private checkShelfsWidth(width: number) {
    let depth = this.state.system.depth;

    let shelf = this.shelfs.find(currentShelf => currentShelf.depth === depth && currentShelf.width === width);
    if (shelf) {
      return true;
    } else {
      return false;
    }
  }

  protected init() {
    for (let i = 0; i < this.surfaces.length; i++) {
      this.surfacesSelect.push({
        value: this.surfaces[i],
        label: this.surfacesText!.find(currenct => currenct.value === this.surfaces[i])!.label
      });
    }

    let depths = new Set<number>();
    let widths = new Set<number>();

    for (let i = 0; i < this.shelfs.length; i++) {
      if (!depths.has(this.shelfs[i].depth)) {
        depths.add(this.shelfs[i].depth);
      }
      if (!widths.has(this.shelfs[i].width)) {
        widths.add(this.shelfs[i].width);
      }
    }

    let instance = this;
    Array.from(depths).sort(function (a, b) { return a - b; }).forEach(function (depth) {
      instance.depthsSelect.push({
        value: depth,
        label: depth + " mm"
      });
    });

    Array.from(widths).sort(function (a, b) { return a - b; }).forEach(function (width) {
      instance.shelfsWidthSelect.push({
        value: width,
        label: width + " mm"
      });
    });

    for (let i = 0; i < this.shelfsNumber.length; i++) {
      this.shelfsNumberSelect.push({
        value: this.shelfsNumber[i],
        label: this.shelfsNumber[i] + " db"
      });
    }

    for (let i = 0; i < this.fieldsNumber.length; i++) {
      this.fieldsNumberSelect.push({
        value: this.fieldsNumber[i],
        label: this.fieldsNumber[i] + " db"
      });
    }
  }

  componentDidMount() {
    document.title = this.name + " - " + SITE.title;

    this.setState({
      system: {
        surface: this.surfacesSelect[0].value,
        fieldsNumber: this.fieldsNumberSelect[0].value,
        depth: this.depthsSelect[0].value,
        height: this.heightsSelect[0].value,
        shelfsNumber: this.shelfsNumberSelect[0].value,
        shelfsWidth: [
          this.shelfsWidthSelect[0].value,
          this.shelfsWidthSelect[0].value,
          this.shelfsWidthSelect[0].value,
          this.shelfsWidthSelect[0].value
        ]
      }
    });

    this.systemChanged();
  }

  private systemChangedHandled = true;
  public systemChanged() {
    this.systemChangedHandled = false;
  }

  private draw(forceInit = false) {
    if (this.virtualShelf.checkContainer()) {
      this.virtualShelf.reDrawVirtualShelf(
        this.state.system.shelfsWidth[0],
        this.state.system.shelfsWidth[1],
        this.state.system.shelfsWidth[2],
        this.state.system.shelfsWidth[3],
        this.state.system.depth,
        this.state.system.height,
        this.state.system.shelfsNumber,
        this.state.system.fieldsNumber,
        forceInit);
    }
  }

  protected getSystem() : ISystem{
    return {
      depth: this.state.system.depth,
      fieldsNumber: this.state.system.fieldsNumber,
      height: this.state.system.height,
      shelfsNumber: this.state.system.shelfsNumber,
      shelfsWidth: this.state.system.shelfsWidth,
      surface: this.state.system.surface
    };
  }

  private preivewTabHandled = false;
  public previewTabChange() {
    let newPreviewTab = this.state.previewTab ? 0 : 1;
    this.preivewTabHandled = false;
    this.setState({ previewTab: newPreviewTab });
  }

  componentDidUpdate() {
    if (!this.systemChangedHandled){
      this.systemChangedHandled = true;

      this.calculatePrice();
      this.draw();
    }

    if (this.state.previewTab === 1 && !this.preivewTabHandled) {
      this.preivewTabHandled = true;
      this.draw(true);
    }    
  }

  sendContact() {
    let validateFields = this.validateFields();

    if (validateFields) {
      this.setState({ contact: { ...this.state.contact, sendStatus: SendStatusEnum.InProgress } });

      let body = {
        name: this.state.contact.name,
        telephone: this.state.contact.telephone,
        email: this.state.contact.email,
        comment: this.state.contact.comment,
        system: {
          type: this.type,
          surface: this.state.system.surface,
          height: this.state.system.height,
          depth: this.state.system.depth,
          fieldsNumber: this.state.system.fieldsNumber,
          shelfsNumber: this.state.system.shelfsNumber,
          shelfsWidth: this.state.system.shelfsWidth.slice(0, this.state.system.fieldsNumber),
          comment: this.state.contact.comment
        }
      };

      let callerInstance = this;

      axios.post('https://api.polckalkulator.hu/contact', body)
        .then(function (response) {
          callerInstance.setState({ contact: { ...callerInstance.state.contact, sendStatus: SendStatusEnum.Success } });
        })
        .catch(function (error) {
          callerInstance.setState({ contact: { ...callerInstance.state.contact, sendStatus: SendStatusEnum.Failed } });
        });
    }
  }

  private validateFields() {
    let emailValid = this.validateEmail(this.state.contact.email);
    let telephoneValid = this.validateTelephone(this.state.contact.telephone);
    let nameValid = this.validateName(this.state.contact.name);
    let termsValid = this.validateTerms();

    this.setState({
      contact: {
        ...this.state.contact,
        fieldValidation: {
          ...this.state.contact.fieldValidation,
          email: emailValid ? ValidationInputState.Valid : ValidationInputState.Invalid,
          telephone: emailValid ? ValidationInputState.Valid : ValidationInputState.Invalid,
          name: nameValid ? ValidationInputState.Valid : ValidationInputState.Invalid,
          termsAccepted: termsValid ? ValidationInputState.Valid : ValidationInputState.Invalid
        }
      }
    });

    if (emailValid && telephoneValid && nameValid && termsValid) {
      return true;
    }
    else {
      return false;
    }
  }

  private validateEmail(email: string) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  private emailOnChange(email: string) {
    let validateEmail = this.validateEmail(email);
    this.setState({
      contact: {
        ...this.state.contact,
        email: email,
        fieldValidation: {
          ...this.state.contact.fieldValidation,
          email: validateEmail ? ValidationInputState.Valid : ValidationInputState.Invalid
        }
      }
    });
  }

  private validateTelephone(telephone: string) {
    var regex = /^(^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$)/;
    return regex.test(String(telephone).toLowerCase());
  }

  private telephoneOnChange(telephone: string) {
    let validateTelephone = this.validateTelephone(telephone);

    this.setState({
      contact: {
        ...this.state.contact,
        telephone: telephone,
        fieldValidation: {
          ...this.state.contact.fieldValidation,
          telephone: validateTelephone ? ValidationInputState.Valid : ValidationInputState.Invalid
        }
      }
    });
  }

  private validateName(name: string) {
    return name.length > 0;
  }

  private nameOnChange(name: string) {
    let nameValid = this.validateName(name);

    this.setState({
      contact: {
        ...this.state.contact,
        name: name,
        fieldValidation: {
          ...this.state.contact.fieldValidation,
          name: nameValid ? ValidationInputState.Valid : ValidationInputState.Invalid
        }
      }
    });
  }

  private validateTerms(){
    return this.state.contact.termsAccepted;
  }

  private termsOnChange() {
    let currentState = !this.state.contact.termsAccepted;

    this.setState({
      contact: {
        ...this.state.contact,
        termsAccepted: currentState,
        fieldValidation: {
          ...this.state.contact.fieldValidation,
          termsAccepted: currentState ? ValidationInputState.Valid : ValidationInputState.Invalid
        }
      }
    });
  }

  public render() {
    let fieldColumns: any[] = [];
    for (let i = 0; i < 4; i++) {
      fieldColumns.push(
        <div className="shelf-width">
          <label>#{i + 1} mező szélessége</label>
          <Select
            isDisabled={!(this.state.system.fieldsNumber >= (i+1)) && this.shelfsWidthSelect.length > 0}
            filterOption={current => { return this.checkShelfsWidth(current.value); }}
            onChange={current => {
              let widths = this.state.system.shelfsWidth;
              widths[i] = current.value;

              this.setState({system: {
                  ...this.state.system, 
                  shelfsWidth: widths
                }
              });
              this.systemChanged(); 
            }}
            value={this.shelfsWidthSelect.find(current => current.value === this.state.system.shelfsWidth[i])}
            options={this.shelfsWidthSelect} />
        </div>
      );
    }

    return (
      <div className="shelf-calculator">
        <div className="single-page-title-container">
          <h1>{this.name}</h1>
        </div>
        <div className="inputs">
          <div className="basic">
            <label>Kivitel</label>
            <Select
              onChange={(current) => {
                this.setState({system: {
                  ...this.state.system, 
                  surface: current.value
                }})

                this.systemChanged(); 
              }}
              defaultValue={this.surfacesSelect[0]}
              options={this.surfacesSelect} />

            <label>Magasság</label>
            <Select
              onChange={(current) => {
                this.setState({system: {...this.state.system, height: current.value}})
                this.systemChanged(); 
              }}
              defaultValue={this.heightsSelect[0]}
              options={this.heightsSelect} />

            <label>Mélység</label>
            <Select
              onChange={(current) => { 
                this.state.system.depth = current.value; 

                let minWidth = 0;
                for(let i = 0; i < this.shelfsWidthSelect.length; i++){
                  if (this.checkShelfsWidth(this.shelfsWidthSelect[i].value)){
                    minWidth = this.shelfsWidthSelect[i].value;
                    break;
                  }
                }

                this.setState({system: {
                  ...this.state.system, 
                  depth: current.value,
                  shelfsWidth: [minWidth, minWidth, minWidth, minWidth]
                }})
                this.systemChanged(); 
              }}
              defaultValue={this.depthsSelect[0]}
              options={this.depthsSelect} />

            <label>Polcok száma</label>
            <Select
              onChange={(current) => {
                this.setState({system: {...this.state.system, shelfsNumber: current.value}})
                this.systemChanged(); 
              }}
              defaultValue={this.shelfsNumberSelect[0]}
              options={this.shelfsNumberSelect} />

            <label>Mezők száma</label>
            <Select
              onChange={(current) => {
                this.setState({system: {...this.state.system, fieldsNumber: current.value}})
                this.systemChanged(); 
              }}
              defaultValue={this.fieldsNumberSelect[0]}
              options={this.fieldsNumberSelect} />
          </div>
          <div className="preview">
            <div className="switcher" onClick={this.previewTabChange} >
              <div className={this.state.previewTab === 0 ? 'active' : ''}>Kép</div>
              <div className={this.state.previewTab === 1 ? 'active' : ''}>3D</div>
            </div>
            {this.state.previewTab === 0 ? <img src={this.previewImage} /> : null}
            {this.state.previewTab === 1 ? <div id={this.virtualShelfContainerId} className="virtualShelfContainer"></div> : null}
          </div>
          <div className="fields">
            {fieldColumns}
          </div>
        </div>
        <div className="details">
          <p className="price">
            Várható ár: <NumericFormat
              value={this.state.grossPrice}
              displayType={'text'}
              thousandSeparator={' '}
              decimalScale={0}
              suffix={' Ft'} /> (<NumericFormat
              value={this.state.netPrice}
              displayType={'text'}
              thousandSeparator={' '}
              decimalScale={0}
              suffix={' Ft'} /> + ÁFA)
          </p>
        </div>
        <div className="clear"></div>
        <div className="contact">
          <div className="row">
            <div className="col-100">
              <p className="text-center margin-top-30">
                Amennyiben felkeltettük érdeklődését állunk
                rendelkezésére <a href="mailto:info@juhaszpolcrendszer.hu">info@juhaszpolcrendszer.hu</a> email
                címünkön vagy a <a href="tel:+36209682309">+36-20-968-2309</a> telefonszámon vagy
                az alábbi űrlapon keresztül.
              </p>
            </div>
          </div>
          {this.state.contact.sendStatus === SendStatusEnum.Never &&
            <div>
              <div className="row padding-top-25">
                <div className="col-50">
                  <input type="text"
                    placeholder="Név*"
                    value={this.state.contact.name}
                    className={this.state.contact.fieldValidation.name === ValidationInputState.Invalid ? "has-error" : ''}
                    onChange={event => this.nameOnChange(event.target.value)} />
                </div>
                <div className="col-50">
                  <input type="text"
                    placeholder="Telefonszám*"
                    value={this.state.contact.telephone}
                    className={this.state.contact.fieldValidation.telephone === ValidationInputState.Invalid ? "has-error" : ''}
                    onChange={event => this.telephoneOnChange(event.target.value)} />
                </div>
              </div>
              <div className="row padding-top-20">
                <div className="col-100">
                  <input type="email"
                    placeholder="Email*"
                    value={this.state.contact.email}
                    className={this.state.contact.fieldValidation.email === ValidationInputState.Invalid ? "has-error" : ''}
                    onChange={event => this.emailOnChange(event.target.value)} />
                </div>
              </div>
              <div className="row padding-top-20">
                <div className="col-100">
                  <textarea placeholder="Megjegyzés"
                    value={this.state.contact.comment}
                    onChange={event => this.setState({ contact: { ...this.state.contact, comment: event.target.value } })}  ></textarea>
                </div>
              </div>
              <div className="row padding-top-20">
                <div className="col-100">
                  <div className="checkbox">
                    <label className="container">
                      <input type="checkbox"
                        checked={this.state.contact.termsAccepted}
                        onChange={() => this.termsOnChange()} />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <p className={this.state.contact.fieldValidation.termsAccepted === ValidationInputState.Invalid ? "has-error" : ''}>
                    Az <a href={NAVLINKS.aszf} target="_blank">Általános Szerződési Feltételeket</a> és az 
                    <a href={NAVLINKS.adatkezeles} target="_blank">Adatkezelési Tájékoztatót</a> megismertem és elfogadom,
                    hozzájárulok a fent megadott adataim kezeléséhez.*
                  </p>
                </div>
              </div>
              <div className="row padding-top-20">
                <div className="col-100 text-right">
                  <button onClick={this.sendContact}>Küldés</button>
                </div>
              </div>
            </div>}
          {this.state.contact.sendStatus === SendStatusEnum.InProgress &&
            <div className="loading">
              <div className="lds-ripple"><div></div><div></div></div>
            </div>
          }
          {this.state.contact.sendStatus === SendStatusEnum.Success &&
            <div className="success">
              Köszönjük az ajánlatkérést, hamarosan felkeressük!
          </div>
          }
          {this.state.contact.sendStatus === SendStatusEnum.Failed &&
            <div className="failed">
              Hiba történt a küldés közben, kérem vegye fel velünk a kapcsolatot az
              alábbi telefonszámon: <a href="tel:+36209682309">+36-20-968-2309</a>!
          </div>
          }
        </div>
      </div>
    );
  }
}

export default StandardShelfCalculator;