const THREE = require('three')
const OrbitControls = require('three-orbitcontrols')

export class VirtualShelf {
    private static renderer: any = undefined;
    private static scene: any = undefined;
    private static camera: any = undefined;

    private containerId: string = "";
    private boxes: any[] = [];
    private material: any = undefined;

    constructor(containerId: string) {
        this.containerId = containerId;
    }

    checkContainer() {
        if (document.getElementById(this.containerId)) {
            return true;
        } else {
            return false;
        }
    }

    init() {
        let container = document.getElementById(this.containerId);

        let w = container!.clientWidth;
        let h = container!.clientHeight;

        VirtualShelf.scene = new THREE.Scene();
        VirtualShelf.scene.background = new THREE.Color(0xffffff);
        VirtualShelf.scene.fog = new THREE.Fog(0xffffff, 500, 15000);

        VirtualShelf.camera = new THREE.PerspectiveCamera(45, w / h, 10, 100000);
        VirtualShelf.camera.position.z = 3000;
        VirtualShelf.camera.position.y = 3500;

        VirtualShelf.renderer = new THREE.WebGLRenderer({ antialias: true });
        VirtualShelf.renderer.setSize(w, h);
        VirtualShelf.renderer.outputEncoding = THREE.sRGBEncoding;
        VirtualShelf.renderer.toneMapping = THREE.ACESFilmicToneMapping;

        container!.appendChild(VirtualShelf.renderer.domElement);

        let controls = new OrbitControls(VirtualShelf.camera, VirtualShelf.renderer.domElement);
        controls.target.set(0, 3500 / 4, 0);
        controls.maxPolarAngle = 0.9 * Math.PI / 2;
        controls.update();

        let ground = new THREE.Mesh(
            new THREE.PlaneBufferGeometry(25000, 25000),
            new THREE.MeshBasicMaterial({ color: 0xefefef, depthWrite: false })
        );
        ground.rotation.x = - Math.PI / 2;
        ground.renderOrder = 1;
        VirtualShelf.scene.add(ground);

        let grid = new THREE.GridHelper(25000, 25, 0x000000, 0x000000);
        grid.material.opacity = 0.1;
        grid.material.depthWrite = false;
        grid.material.transparent = true;
        VirtualShelf.scene.add(grid);

        let light = new THREE.DirectionalLight(0xffffff, 0.3);
        light.position.x = 0;
        light.position.y = 3500;
        light.position.z = 3000;
        VirtualShelf.scene.add(light);

        this.material = new THREE.MeshLambertMaterial({ color: 0xcccccc, combine: THREE.MixOperation, reflectivity: 0.3 });
    }

    addStand(width, height, y, s) {
        let geometry = new THREE.BoxGeometry(50, height, 1);
        let box = new THREE.Mesh(geometry, this.material);
        box.position.set(s + y / 2 - 25, height / 2, width / 2);
        box.castShadow = true;
        VirtualShelf.scene.add(box);
        this.boxes.push(box);

        geometry = new THREE.BoxGeometry(50, height, 1);
        box = new THREE.Mesh(geometry, this.material);
        box.position.set(s + y / 2 - 25, height / 2, -width / 2);
        box.castShadow = true;
        VirtualShelf.scene.add(box);
        this.boxes.push(box);

        geometry = new THREE.BoxGeometry(1, height, 30);
        box = new THREE.Mesh(geometry, this.material);
        box.position.set(s + y / 2, height / 2, -width / 2 + 15);
        box.castShadow = true;
        VirtualShelf.scene.add(box);
        this.boxes.push(box);

        geometry = new THREE.BoxGeometry(1, height, 30);
        box = new THREE.Mesh(geometry, this.material);
        box.position.set(s + y / 2, height / 2, width / 2 - 15);
        box.castShadow = true;
        VirtualShelf.scene.add(box);
        this.boxes.push(box);

        geometry = new THREE.BoxGeometry(1, 120, width);
        box = new THREE.Mesh(geometry, this.material);
        box.position.set(s + y / 2, height - 60, 0);
        box.castShadow = true;
        VirtualShelf.scene.add(box);
        this.boxes.push(box);

        geometry = new THREE.BoxGeometry(1, 120, width);
        box = new THREE.Mesh(geometry, this.material);
        box.position.set(s + y / 2, height - 60 - (height - 120 - 120 - 120 - 240) / 2, 0);
        box.castShadow = true;
        VirtualShelf.scene.add(box);
        this.boxes.push(box);

        geometry = new THREE.BoxGeometry(1, 120, width);
        box = new THREE.Mesh(geometry, this.material);
        box.position.set(s + y / 2, height - 60 - (height - 120 - 120 - 120 - 240) - 120, 0);
        box.castShadow = true;
        VirtualShelf.scene.add(box);
        this.boxes.push(box);

        geometry = new THREE.BoxGeometry(50, height, 1);
        box = new THREE.Mesh(geometry, this.material);
        box.position.set(s - y / 2 + 25, height / 2, width / 2);
        box.castShadow = true;
        VirtualShelf.scene.add(box);
        this.boxes.push(box);

        geometry = new THREE.BoxGeometry(50, height, 1);
        box = new THREE.Mesh(geometry, this.material);
        box.position.set(s - y / 2 + 25, height / 2, -width / 2);
        box.castShadow = true;
        VirtualShelf.scene.add(box);
        this.boxes.push(box);

        geometry = new THREE.BoxGeometry(1, height, 30);
        box = new THREE.Mesh(geometry, this.material);
        box.position.set(s - y / 2, height / 2, -width / 2 + 15);
        box.castShadow = true;
        VirtualShelf.scene.add(box);
        this.boxes.push(box);

        geometry = new THREE.BoxGeometry(1, height, 30);
        box = new THREE.Mesh(geometry, this.material);
        box.position.set(s - y / 2, height / 2, width / 2 - 15);
        box.castShadow = true;
        VirtualShelf.scene.add(box);
        this.boxes.push(box);

        geometry = new THREE.BoxGeometry(1, 120, width);
        box = new THREE.Mesh(geometry, this.material);
        box.position.set(s - y / 2, height - 60, 0);
        box.castShadow = true;
        VirtualShelf.scene.add(box);
        this.boxes.push(box);

        geometry = new THREE.BoxGeometry(1, 120, width);
        box = new THREE.Mesh(geometry, this.material);
        box.position.set(s - y / 2, height - 60 - (height - 120 - 120 - 120 - 240) / 2, 0);
        box.castShadow = true;
        VirtualShelf.scene.add(box);
        this.boxes.push(box);

        geometry = new THREE.BoxGeometry(1, 120, width);
        box = new THREE.Mesh(geometry, this.material);
        box.position.set(s - y / 2, height - 60 - (height - 120 - 120 - 120 - 240) - 120, 0);
        box.castShadow = true;
        VirtualShelf.scene.add(box);
        this.boxes.push(box);
    }

    addShelf(width, height, y, db, s) {
        let geometry = new THREE.CubeGeometry(y, 1, width);
        let cube = new THREE.Mesh(geometry, this.material);
        cube.position.set(s, height, 0);
        cube.castShadow = true;
        VirtualShelf.scene.add(cube);
        this.boxes.push(cube);

        geometry = new THREE.CubeGeometry(y, 40, 1);
        cube = new THREE.Mesh(geometry, this.material);
        cube.position.set(s, height - 20, width / 2);
        cube.castShadow = true;
        VirtualShelf.scene.add(cube);
        this.boxes.push(cube);

        geometry = new THREE.CubeGeometry(y, 40, 1);
        cube = new THREE.Mesh(geometry, this.material);
        cube.position.set(s, height - 20, -width / 2);
        cube.castShadow = true;
        VirtualShelf.scene.add(cube);
        this.boxes.push(cube);

        geometry = new THREE.CubeGeometry(y, 1, width);
        cube = new THREE.Mesh(geometry, this.material);
        cube.position.set(s, 120, 0);
        cube.castShadow = true;
        VirtualShelf.scene.add(cube);
        this.boxes.push(cube);

        geometry = new THREE.CubeGeometry(y, 40, 1);
        cube = new THREE.Mesh(geometry, this.material);
        cube.position.set(s, 120 - 20, width / 2);
        cube.castShadow = true;
        VirtualShelf.scene.add(cube);
        this.boxes.push(cube);

        geometry = new THREE.CubeGeometry(y, 40, 1);
        cube = new THREE.Mesh(geometry, this.material);
        cube.position.set(s, 120 - 20, -width / 2);
        cube.castShadow = true;
        VirtualShelf.scene.add(cube);
        this.boxes.push(cube);

        for (let i = 1; i < db; i++) {
            geometry = new THREE.CubeGeometry(y, 1, width);
            cube = new THREE.Mesh(geometry, this.material);
            cube.position.set(s, 120 + (height - 120) / (db - 1) * (i), 0);
            cube.castShadow = true;
            VirtualShelf.scene.add(cube);
            this.boxes.push(cube);

            geometry = new THREE.CubeGeometry(y, 40, 1);
            cube = new THREE.Mesh(geometry, this.material);
            cube.position.set(s, 120 + (height - 120) / (db - 1) * (i) - 20, width / 2);
            cube.castShadow = true;
            VirtualShelf.scene.add(cube);
            this.boxes.push(cube);

            geometry = new THREE.CubeGeometry(y, 40, 1);
            cube = new THREE.Mesh(geometry, this.material);
            cube.position.set(s, 120 + (height - 120) / (db - 1) * (i) - 20, -width / 2);
            cube.castShadow = true;
            VirtualShelf.scene.add(cube);
            this.boxes.push(cube);
        }
    }

    static render() {
        if (VirtualShelf.renderer) {
            VirtualShelf.renderer.clear();
            requestAnimationFrame(VirtualShelf.render);

            VirtualShelf.renderer.render(VirtualShelf.scene, VirtualShelf.camera);
        }
    }

    reDrawVirtualShelf(width1, width2, width3, width4, depth, height, shelf_count, comp_count, forceInit = false) {
        if (!VirtualShelf.scene) {
            this.init();
        }
        else if (forceInit){
            this.init();
        }

        for (let i = 0; i < this.boxes.length; i++) {
            this.boxes[i].material.dispose();
            this.boxes[i].geometry.dispose();
            VirtualShelf.scene.remove(this.boxes[i]);
        }

        if (comp_count === 1) {
            this.addStand(depth, height, width1, 0);
            this.addShelf(depth, height, width1, shelf_count, 0);
        }
        else if (comp_count === 2) {
            this.addStand(depth, height, width1, -width1 / 2);
            this.addShelf(depth, height, width1, shelf_count, -width1 / 2);
            this.addStand(depth, height, width2, width2 / 2);
            this.addShelf(depth, height, width2, shelf_count, width2 / 2);
        }
        else if (comp_count === 3) {
            this.addStand(depth, height, width1, -width1 / 2 - width2 / 2);
            this.addShelf(depth, height, width1, shelf_count, -width1 / 2 - width2 / 2);
            this.addStand(depth, height, width2, 0);
            this.addShelf(depth, height, width2, shelf_count, 0);
            this.addStand(depth, height, width3, width2 / 2 + width3 / 2);
            this.addShelf(depth, height, width3, shelf_count, width2 / 2 + width3 / 2);
        }
        else if (comp_count === 4) {
            this.addStand(depth, height, width1, -width1 / 2 - width2);
            this.addShelf(depth, height, width1, shelf_count, -width1 / 2 - width2);
            this.addStand(depth, height, width2, -width2 / 2);
            this.addShelf(depth, height, width2, shelf_count, -width2 / 2);
            this.addStand(depth, height, width3, width3 / 2);
            this.addShelf(depth, height, width3, shelf_count, width3 / 2);
            this.addStand(depth, height, width4, Number(width3) + Number(width4) / 2);
            this.addShelf(depth, height, width4, shelf_count, Number(width3) + Number(width4) / 2);
        }

        VirtualShelf.render();
    }
}